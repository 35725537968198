import moment, { localMomentInUTC } from 'shared/lib/moment'
import SettingUtils from 'shared/utils/settings'
import OperatingHoursUtils, { getCurrentAwayPeriod } from 'shared/utils/operating_rules'
import merge from 'lodash/merge'

export function browserUseAmPm () {
  const date = new Date(Date.UTC(2013, 11, 31, 3, 0, 0))
  const timeString = date.toLocaleTimeString()

  return !!(timeString.match(/am|pm/i) || timeString.toString().match(/am|pm/i))
}

window.browserUseAmPm = browserUseAmPm()

const extractAmPm = (string) => {
  const amMatcher = string.match(/am/gi)
  const pmMatcher = string.match(/pm/gi)

  if (amMatcher) {
    return 'am'
  } else if (pmMatcher) {
    return 'pm'
  } else {
    return null
  }
}

export const parseFixedDefaultTime = (rawDefaultTime) => {
  const minute = rawDefaultTime.split(/:|\./)[1]?.match(/\d*/)[0]
  const amOrPm = extractAmPm(rawDefaultTime)

  let hour = rawDefaultTime.split(/:|\./)[0]?.match(/\d*/)[0]

  if (amOrPm) {
    if (hour === '12') {
      hour = '0'
    }

    if (amOrPm === 'pm') {
      hour = parseInt(hour, 10) + 12
    }
  }

  return { hour, minute }
}

export const defaultTime = (type, options = {}) => {
  let time = options.date ? moment(options.date) : localMomentInUTC()

  const typeSetting = SettingUtils.get(`orders.${type}_type`, 'relative')

  switch (typeSetting) {
    case 'relative': {
      const offset = parseFloat(SettingUtils.get(`orders.${type}_relative_offset`, 0))

      time.add(offset, 'hour')

      break
    }
    case 'fixed': {
      const value = SettingUtils.get(`orders.${type}_fixed_at`, '09:00') || '09:00'
      const { hour, minute } = parseFixedDefaultTime(value)

      if (hour) time = time.hour(hour)
      if (minute) time = time.minute(minute)

      break
    }
  }

  // Round to the minute
  time.set('millisecond', 0)
  time.set('second', 0)

  const minute = time.minute()

  if (typeSetting === 'relative') {
    // Round per 15 minutes
    if (minute === 0) {
      // Do nothing
    } else if (minute < 15) {
      time.set('minute', 15)
    } else if (minute < 30) {
      time.set('minute', 30)
    } else if (minute < 45) {
      time.set('minute', 45)
    } else if (minute <= 59) {
      time.set('minute', 0)
      time.add(1, 'hour')
    }
  }

  if (options.defaultStartTime) {
    // If the defaultStopTime is before the defaultStartTime, we overflow to the next day.
    // The duration will always still be less than a day (24 hours).
    if (time.isSameOrBefore(options.defaultStartTime)) {
      time.add(1, 'day')
    }
  }

  // If the time we calculate is before the defaultStartTime (or the current time),
  // we overflow to the next day.
  if (type === 'stop' && time.isSameOrBefore(options.defaultStartTime || moment())) {
    // Start time is higher than the stop time, add the diff in days
    const diffInDays = Math.abs(time.diff(options.defaultStartTime || moment(), 'days'))

    time.add(diffInDays + 1, 'day')
  }

  // Make sure we are outside of an away period
  const currentAwayPeriod = getCurrentAwayPeriod(time)

  if (currentAwayPeriod) {
    const awayEnd = moment(currentAwayPeriod.data.away.till)

    time = time.set({ year: awayEnd.year(), month: awayEnd.month(), date: awayEnd.date() })
  }

  if (options.useOpeningHours !== false) {
    if (options.dontChangeDate !== true) {
      // Look for first available day if day isn't available
      while (!OperatingHoursUtils.isTimeSelectable(time, merge({}, options, { dateOnly: true }))) {
        time.add(1, 'day')
      }
    }

    if (!OperatingHoursUtils.isTimeSelectable(time, options)) {
      if (type === 'start') {
        time.startOf('day')
      } else {
        time.endOf('day')
      }

      while (!OperatingHoursUtils.isTimeSelectable(time, options)) {
        // 96 15 minute segments in a day (60 * 24 / 15)
        for (let i = 1; i <= 96; ++i) {
          if (type === 'start') {
            time.add(15, 'minutes')
          } else {
            if (time.minutes() === 59) {
              time.subtract(14, 'minutes')
            } else {
              time.subtract(15, 'minutes')
            }
          }

          if (OperatingHoursUtils.isTimeSelectable(time, options)) {
            return time
          }
        }

        if (type === 'stop') {
          time.add(1, 'day')
          time.endOf('day')
        }
      }
    }
  }

  return time
}

export const defaultStartTime = (options = {}) => {
  return defaultTime('start', options)
}

export const defaultStopTime = (options = {}) => {
  return defaultTime('stop', options)
}

export default {
  defaultStartTime,
  defaultStopTime,
  defaultTime
}
